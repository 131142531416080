import type {
  Alert,
  Article,
  Charge,
  ChargePage,
  Equipments,
  InvoicePage,
  Period,
  StatsCharges,
  UserAPI,
} from '~/shared/types';
import type { Graph } from '~/shared/Graph';
import { uuidv4 } from '@firebase/util';
import { formatDate } from '~/server/utils/formatDate';
import { diffDates } from '~/server/utils/diffDates';

export async function getArticles(token: string): Promise<Article[]> {
  const response = await fetch('/api/infos/getArticles', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'X-Request-ID': uuidv4(),
      'X-Correlation-ID': uuidv4(),
    },
  });
  return response.json();
}

export async function getAlert(token: string): Promise<Alert> {
  const response = await fetch('/api/infos/getAlert', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'X-Request-ID': uuidv4(),
      'X-Correlation-ID': uuidv4(),
    },
  });
  return response.json();
}

export async function getUserDetails(userId: string, token: string): Promise<UserAPI | null> {
  const response = await fetch(`/api/documents/getUserDetails?userId=${userId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'X-Request-ID': uuidv4(),
      'X-Correlation-ID': uuidv4(),
    },
  });
  if (response.status === 204) {
    return null;
  }
  if (!response.ok) {
    throw new Error("Erreur lors de la récupération du détail de l'utilisateur");
  }
  return await response.json();
}

export async function getEquipmentsByUser(token: string, userId: string): Promise<Equipments> {
  const response = await fetch(`/api/equipments/byUser?userId=${userId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'X-Request-ID': uuidv4(),
      'X-Correlation-ID': uuidv4(),
    },
  });
  if (!response.ok) {
    throw new Error('Erreur lors de la récupération des données équipements');
  }
  return response.json();
}

export async function getEmpCharges(
  token: string,
  dateStart: Date,
  dateEnd: Date,
  bornes: string[],
  badges: string[],
  drivers: string[],
  page: number,
): Promise<ChargePage> {
  if (badges.length === 0 && drivers.length === 0) {
    return {
      data: [],
      _metadatas: {
        max_pages: 0,
      },
    };
  }
  const responseCharges = await fetch('/api/badges/getCharges', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'X-Request-ID': uuidv4(),
      'X-Correlation-ID': uuidv4(),
    },
    body: JSON.stringify({
      dateStart: dateStart.toISOString(),
      dateEnd: dateEnd.toISOString(),
      bornes: bornes,
      badges: badges,
      drivers: drivers,
      page: page,
    }),
  });
  return responseCharges.json();
}

// eslint-disable-next-line max-lines-per-function
export async function getStatsCharges(
  token: string,
  dateStart: Date,
  dateEnd: Date,
  bornes: string[],
  badges: string[],
  drivers: string[],
): Promise<StatsCharges> {
  if (badges.length === 0 && drivers.length === 0) {
    return {
      total_charges: 0,
      total_energy: 0,
      medium_duration: '00:00:00',
      medium_energy: 0,
    };
  }
  let page = 1;
  let total_pages = Number.MAX_VALUE;
  const charges: Charge[] = [];
  while (page <= total_pages) {
    const responseCharges = await fetch('/api/badges/getCharges', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'X-Request-ID': uuidv4(),
        'X-Correlation-ID': uuidv4(),
      },
      body: JSON.stringify({
        dateStart: dateStart.toISOString(),
        dateEnd: dateEnd.toISOString(),
        bornes: bornes,
        badges: badges,
        drivers: drivers,
      }),
    });
    page++;
    const data = await responseCharges.json();
    total_pages = data._metadatas.max_pages;
    charges.push(...data.data);
  }
  if (charges.length === 0) {
    return {
      total_charges: 0,
      total_energy: 0,
      medium_duration: '00:00:00',
      medium_energy: 0,
    };
  }
  return {
    total_charges: charges.length,
    total_energy: charges.reduce((acc, charge) => acc + charge.total_energy_kwh, 0),
    medium_duration: formatDate(
      Math.trunc(
        charges.reduce((acc, charge) => acc + diffDates(charge.start_date_time, charge.end_date_time), 0) /
          charges.length,
      ),
    ),
    medium_energy: charges.reduce((acc, charge) => acc + charge.total_energy_kwh, 0) / charges.length,
  };
}

export async function getLastFiveEmpCharge(token: string, userId: string): Promise<Charge[]> {
  const response = await fetch('/api/badges/getFiveCharges', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'X-Request-ID': uuidv4(),
      'X-Correlation-ID': uuidv4(),
    },
    body: JSON.stringify({
      userId: userId,
    }),
  });
  if (!response.ok) {
    throw new Error('Erreur lors de la récupération des données');
  }
  return response.json();
}

export async function getCpoGraph(
  token: string,
  date: Date,
  period: Period,
  bornes: string[],
  badges: string[],
  drivers: string[],
): Promise<Graph> {
  const body = {
    date: date,
    period: period,
    bornes: bornes,
    badges: badges,
    drivers: drivers,
  };
  const response = await fetch('/api/bornes/getGraph', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'X-Request-ID': uuidv4(),
      'X-Correlation-ID': uuidv4(),
    },
    body: JSON.stringify(body),
  });
  return response.json();
}

export async function updateCustomName(token: string, params: string[], userId: string) {
  const response = await fetch('/api/equipments/updateName', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'X-Request-ID': uuidv4(),
      'X-Correlation-ID': uuidv4(),
    },
    body: JSON.stringify({
      id: params[1],
      custom_name: params[2],
      collection: params[0],
      user_id: userId,
    }),
  });

  return response.json();
}

export async function getInvoices(token: string, userId: string): Promise<InvoicePage> {
  const response = await fetch('/api/documents/getInvoices', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'X-Request-ID': uuidv4(),
      'X-Correlation-ID': uuidv4(),
    },
    body: JSON.stringify({
      user_id: userId,
    }),
  });
  return await response.json();
}
