import type { User, UserAPI } from '~/shared/types';
import { getAccessToken } from '~/utils/tokens';
import { getUserInfo } from '~/utils/userClient';
import { getUserDetails } from '~/utils/serverClient';

// eslint-disable-next-line max-lines-per-function
export async function getUserData(): Promise<User | undefined> {
  if (document.cookie === '') {
    navigateTo({ path: '/' });
  }
  const config = useRuntimeConfig();

  try {
    const token = getAccessToken();
    if (!token) {
      return undefined;
    }
    const data = await getUserInfo(token);
    const userDetails: UserAPI | null = await getUserDetails(data.user_id, config.public.APP_TOKEN);
    if (userDetails === null) {
      return data;
    }
    const principalEmail = userDetails.emails.find((mail) => (mail.isPrincipal = true));
    return {
      addresses: [
        {
          type: userDetails.addresses[0].type,
          street: userDetails.addresses[0].street,
          postCode: userDetails.addresses[0].zip.toString(),
          city: userDetails.addresses[0].city,
          country: userDetails.addresses[0].country,
        },
      ],
      birth_date: data.birth_date,
      created_at: data.created_at,
      email: principalEmail ? principalEmail.value : data.email,
      email_verified: data.email_verified,
      enabled: data.enabled,
      family_name: userDetails.lastname ?? data.family_name,
      gender: data.gender,
      given_name: userDetails.firstname ?? data.given_name,
      id: data.id,
      nickname: userDetails.name ?? `${userDetails.firstname} ${userDetails.lastname}`,
      phone: userDetails.phones[0],
      preferred_username: userDetails.name ?? data.preferred_username,
      updated_at: data.updated_at,
      user_id: data.user_id,
      user_roles: data.user_roles,
    };
  } catch (error: unknown) {
    console.log(error);
  }
}
